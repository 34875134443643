<template>
	<Dialog :dialog.sync="importDialog" @close="onClose">
		<template v-slot:title>Import {{ title }} </template>
		<template v-slot:body>
			<v-row>
				<v-col md="12">
					<p class="btx-p">
						Download a <a :href="sampleFile" download>sample file</a> and compare it to your import file
						to ensure you have the perfect file for the import.
					</p>
				</v-col>
				<v-col md="4">
					<label for="xls-csv-file" class="required btx-label">Upload file</label>
				</v-col>
				<v-col md="8">
					<v-file-input
						id="xls-csv-file"
						v-model="file"
						class="mt-0 pt-0"
						placeholder="Choose File"
						prepend-icon="mdi-paperclip"
						accept=".xlsx,.csv,.xls"
					>
						<template v-slot:selection="{ text }">
							<v-chip small label color="primary">
								{{ text }}
							</v-chip>
						</template>
					</v-file-input>
					<p class="btx-small-p">Maximum File Size: 5 MB | File Format: CSV or XLSX</p>
				</v-col>
				<v-col md="12">
					<template v-for="(data, index) in responseMsg">
						<p style="color: red" :key="index">
							{{ data }}
						</p>
					</template>
				</v-col>
				<!-- <v-col md="4">
					<label for="duplicate-handling" class="required btx-label">Duplicate Handling</label>
				</v-col>
				<v-col md="8">
					<v-radio-group id="duplicate-handling" class="mt-0 pt-0" v-model="duplicate_handling" column>
						<v-radio label="Skip" value="skip"></v-radio>

						<p class="btx-p">
							Retains the records in {{ $APPNAME }} and does not sync the identical records from the import
							file.
						</p>
						<v-radio label="Overwrite" value="overwrite"></v-radio>
						<p class="btx-p">
							Overwrites existing records in {{ $APPNAME }} with the identical records in the import file.
						</p>
					</v-radio-group>
				</v-col> -->
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				v-on:click="importDocuments"
				:disabled="pageLoading"
				:loading="pageLoading"
			>
				Import
			</v-btn>
			<v-btn depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog.vue";
import ApiService from "@/core/services/api.service"; /* 
import { GET_LISTING } from "@/core/services/store/listing.module"; */
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";

export default {
	name: "import-dialog",
	data() {
		return {
			pageTitle: "Assets",
			duplicate_handling: "skip",
			pageLoading: false,
			file: null,
			responseMsg: [],
		};
	},
	props: {
		importDialog: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		customerDataId: {
			type: Number,
			default: 0,
		},
		contractDataId: {
			type: Number,
			default: 0,
		},
		projectDataId: {
			type: Number,
			default: 0,
		},
		seoDataId: {
			type: Number,
			default: 0,
		},
		endpoint: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		sampleFile: {
			type: String,
			default: "",
		},
		module: {
			type: String,
			default: "",
		},
	},
	mounted() {},
	methods: {
		/* getListing() {
			this.pageLoading = true;

			const relational = new Object({});

			if (this.internal) {
				relational["internal"] = 1;
				relational["type"] = this.relation_type;
				relational["type-id"] = this.relation_type_id;
			}

			const filter = new Object({
				status: "all",
				...this.$route.query,
				...this.defaultFilter,
				...relational,
				tz: new Date().getTime(),
			});
			this.$store
				.dispatch(GET_LISTING, { url: "asset", filter })
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.clearSelection();
					this.pageLoading = false;
				});
		}, */

		importDocuments() {
			this.pageLoading = true;
			ApiService.setHeader();
			const formData = new FormData();
			if (!this.file) {
				this.$store.commit(SET_ERROR, [{ model: true, message: "File is required!" }]);
				this.pageLoading = false;
				return false;
			}

			formData.append("excel_file", this.file, this.file.name);
			formData.append("type", this.type);
			formData.append("extension", "xlsx");
			formData.append("project_id", this.projectDataId);
			formData.append("seo_id", this.seoDataId);

			/* formData.append("customer", this.customerDataId ? this.customerDataId : 0);
			formData.append("contract", this.contractDataId ? this.contractDataId : 0);
			formData.append("project", this.projectDataId ? this.projectDataId : 0);
			formData.append("seo", this.seoDataId ? this.seoDataId : 0); */

			ApiService.upload(this.endpoint, formData)
				.then((data) => {
					this.responseMsg = data.messages;
					if (data.status) {
						this.$store.commit(SET_MESSAGE, [{ model: true, message: "Document uploaded successfully" }]);
						this.$emit("close", true);
						this.$emit("refress", true);
					}
				})
				.catch((error) => {
					console.log({ error });
					this.pageLoading = false;
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		onClose() {
			console.log("<== Close ==>");
			this.$emit("close", true);
		},
	},
	components: {
		Dialog,
	},
};
</script>
